import { lazy } from 'react';
import IRoute from './route.interface';
import { ROUTES } from '../App.constant';
import SettingsAppWrapper from '../components/AppWrappers.tsx/SettingsAppWrapper';
import AppWrapper from '../components/AppWrappers.tsx/AppWrapper';

const Manuscripts = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Manuscript/Manuscripts' /* webpackChunkName:"Manuscripts" */
        )
);
const CreateOrEditIssue = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Issue/CreateOrEditIssue' /* webpackChunkName:"CreateOrEditIssue" */
        )
);
const Issues = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Issue/Issues' /* webpackChunkName:"Issues" */
        )
);
const Issue = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Issue/Issue' /* webpackChunkName:"Issue" */
        )
);
const Manuscript = lazy(
    () =>
        import(
            '../pages/authenticatedPages/ManagerialEditor/Manuscript' /* webpackChunkName:"Manuscript" */
        )
);
const PersonalSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/pages/PersonalSettings' /* webpackChunkName:"PersonalSettings" */
        )
);
const AffiliatesSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/pages/AffiliatesSettings' /* webpackChunkName:"AffiliatesSettings" */
        )
);
const ShippingSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/pages/ShippingSettings' /* webpackChunkName:"ShippingSettings" */
        )
);

const CollaborationPage = lazy(
    () =>
        import(
            '../pages/public/pages/Collaborations/CollaborationPage' /* webpackChunkName:"CollaborationPage" */
        )
);

const InvalidRolePage = lazy(
    () =>
        import(
            '../pages/public/pages/InvalidRolePage' /* webpackChunkName:"InvalidRolePage" */
        )
);

const ManagerialEditorRoute = (): IRoute[] => {
    return [
        {
            element: Manuscripts,
            path: ROUTES.DASHBOARD,
            wrapper: AppWrapper,
        },
        {
            element: Manuscripts,
            path: ROUTES.MANUSCRIPTS,
            wrapper: AppWrapper,
        },
        {
            element: Manuscript,
            path: ROUTES.MANUSCRIPTS + '/:manuscriptId',
            wrapper: AppWrapper,
        },
        {
            element: InvalidRolePage,
            path: ROUTES.NEW_MANUSCRIPT,
            wrapper: AppWrapper,
        },
        {
            element: Issues,
            path: ROUTES.PUBLICATIONS,
            wrapper: AppWrapper,
        },
        {
            element: CreateOrEditIssue,
            path: ROUTES.NEW_ISSUE,
            wrapper: AppWrapper,
        },
        {
            element: CreateOrEditIssue,
            path: ROUTES.EDIT_ISSUE + '/:publicationId',
            wrapper: AppWrapper,
        },
        {
            element: Issue,
            path: ROUTES.PUBLICATIONS + '/:publicationId',
            wrapper: AppWrapper,
        },
        {
            element: PersonalSettings,
            path: ROUTES.SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: PersonalSettings,
            path: ROUTES.PERSONAL_INFORMATION_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: AffiliatesSettings,
            path: ROUTES.AFFILIATES_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: ShippingSettings,
            path: ROUTES.SHIPPING_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: CollaborationPage,
            path: ROUTES.PUBLIC_COLLABORATIONS + '/:id',
        },
    ];
};

export default ManagerialEditorRoute;
