import { ROUTES } from '../App.constant';
import { lazy, useMemo } from 'react';
import IRoute from './route.interface';
import EmailVerification from '../pages/authentication/EmailVerification';
import Error404 from '../pages/public/pages/404';
import { useLocation } from 'react-router';
import { useAuth } from '../services/authentication/auth.queries';

const Landing = lazy(
    () =>
        import('../pages/public/pages/Landing' /* webpackChunkName:"Landing" */)
);
const JournalsPage = lazy(
    () =>
        import(
            '../pages/public/pages/journalsPage' /* webpackChunkName:"JournalsPage" */
        )
);
const JournalPage = lazy(
    () =>
        import(
            '../pages/public/pages/Journals/JournalPage' /* webpackChunkName:"JournalPage" */
        )
);
const AffiliatesPage = lazy(
    () =>
        import(
            '../pages/public/pages/AffiliatesPage' /* webpackChunkName:"AffiliatesPage" */
        )
);
const AffiliatePage = lazy(
    () =>
        import(
            '../pages/public/pages/AffiliatePage' /* webpackChunkName:"AffiliatePage" */
        )
);
const AboutUS = lazy(
    () =>
        import('../pages/public/pages/AboutUs' /* webpackChunkName:"AboutUS" */)
);
const CollaborationsPage = lazy(
    () =>
        import(
            '../pages/public/pages/Collaborations/CollaborationsPage' /* webpackChunkName:"CollaborationsPage" */
        )
);
const CollaborationPage = lazy(
    () =>
        import(
            '../pages/public/pages/Collaborations/CollaborationPage' /* webpackChunkName:"CollaborationPage" */
        )
);
const ContactUs = lazy(
    () =>
        import(
            '../pages/public/pages/ContactUs' /* webpackChunkName:"ContactUs" */
        )
);
const ArticlesPage = lazy(
    () =>
        import(
            '../pages/public/pages/Articles/ArticlesPage' /* webpackChunkName:"ContactUs" */
        )
);
const SearchPage = lazy(
    () =>
        import(
            '../pages/public/pages/SearchPage' /* webpackChunkName:"SearchPage" */
        )
);
const PrivacyPolicy = lazy(
    () =>
        import(
            '../pages/public/pages/PrivacyPolicy' /* webpackChunkName:"PrivacyPolicy" */
        )
);
const TermsAndCondition = lazy(
    () =>
        import(
            '../pages/public/pages/TermsAndCondition' /* webpackChunkName:"TermsAndCondition" */
        )
);
const Faqs = lazy(
    () => import('../pages/public/pages/Faqs' /* webpackChunkName:"Faqs" */)
);
const BecomeReviewer = lazy(
    () =>
        import(
            '../pages/public/pages/BecomeReviewer' /* webpackChunkName:"BecomeReviewer" */
        )
);
const NewsAndBlogs = lazy(
    () =>
        import(
            '../pages/public/pages/NewsAndBlogs' /* webpackChunkName:"NewsAndBlogs" */
        )
);
const Blog = lazy(
    () => import('../pages/public/pages/Blog' /* webpackChunkName:"Blog" */)
);
const ArticlePage = lazy(
    () =>
        import(
            '../pages/public/pages/ArticlePage' /* webpackChunkName:"ArticlePage" */
        )
);
const BoardMemberPage = lazy(
    () =>
        import(
            '../pages/public/pages/BoardMemberPage' /* webpackChunkName:"BoardMemberPage" */
        )
);

const IssuePage = lazy(
    () =>
        import(
            '../pages/public/pages/IssuePage' /* webpackChunkName:"IssuePage" */
        )
);

const PublicRoutes = (): IRoute[] => {
    const { data } = useAuth();
    const location = useLocation();

    const isInternalRoute = useMemo(() => {
        if (data) return ROUTES.DASHBOARD;
        return ROUTES.SIGN_IN;
    }, []);

    return [
        {
            element: Landing,
            path: ROUTES.HOME,
        },
        {
            element: () => {
                if (
                    location.pathname.includes('manuscript') ||
                    location.pathname.includes('dashboard') ||
                    location.pathname.includes('review')
                ) {
                    window.location.href = isInternalRoute;
                    return null;
                } else {
                    return <Error404 />;
                }
            },
            path: '*',
        },
        {
            element: JournalsPage,
            path: ROUTES.JOURNALS,
        },
        {
            element: JournalPage,
            path: ROUTES.JOURNALS + '/:slug',
        },
        {
            element: AffiliatesPage,
            path: ROUTES.AFFILIATES,
        },
        {
            element: AffiliatePage,
            path: ROUTES.INSTITUTIONS + '/:slug',
        },
        {
            element: CollaborationsPage,
            path: ROUTES.PUBLIC_COLLABORATIONS,
        },
        {
            element: CollaborationPage,
            path: ROUTES.PUBLIC_COLLABORATIONS + '/:slug',
        },
        {
            element: AboutUS,
            path: ROUTES.ABOUT_US,
        },
        {
            element: ContactUs,
            path: ROUTES.CONTACT_US,
        },
        {
            element: SearchPage,
            path: ROUTES.SEARCH_PAGE,
        },
        {
            element: PrivacyPolicy,
            path: ROUTES.PRIVACY_POLICY,
        },
        {
            element: TermsAndCondition,
            path: ROUTES.TERMS_AND_CONDITIONS,
        },
        {
            element: Faqs,
            path: ROUTES.FAQS,
        },
        {
            element: BecomeReviewer,
            path: ROUTES.BECOME_REVIEWER,
        },
        {
            element: NewsAndBlogs,
            path: ROUTES.NEWS_AND_BLOGS,
        },
        {
            element: Blog,
            path: ROUTES.NEWS_AND_BLOGS + '/:slug',
        },
        {
            element: ArticlesPage,
            path: ROUTES.ARTICLES,
        },
        {
            element: ArticlePage,
            path: ROUTES.ARTICLES + '/:slug',
        },
        {
            element: BoardMemberPage,
            path: ROUTES.PROFILE_PAGE + '/:slug',
        },
        {
            element: IssuePage,
            path: ROUTES.ISSUES + '/:slug',
        },
        {
            element: EmailVerification,
            path: ROUTES.EMAIL_VERIFICATION,
        },
    ];
};

export default PublicRoutes;
